import "normalize.css"
import "./src/assets/css/fonts.scss"
import "./src/assets/css/main.scss"
import "./src/assets/css/forms.scss"

import "./src/assets/css/layout/header.scss"
import "./src/assets/css/layout/sidebar.scss"
import "./src/assets/css/layout/footer.scss"
import "./src/assets/css/layout/navbar.scss"
import "./src/assets/css/pages/home.scss"
import "./src/assets/css/pages/about-us.scss"
import "./src/assets/css/pages/our-partners.scss"
import "./src/assets/css/pages/about-the-team.scss"
import "./src/assets/css/pages/current-financial-year.scss"
import "./src/assets/css/pages/works.scss"
import "./src/assets/css/pages/works-subpage.scss"
import "./src/assets/css/pages/stay-in-touch.scss"
import "./src/assets/css/pages/privacy-policy.scss"
import "./src/assets/css/pages/error.scss"
import "./src/assets/css/pages/ckeditor.scss"
import "./src/assets/css/pages/contribute.scss"
import "./src/assets/css/pages/about-the-patron.scss"
import "./src/assets/css/pages/content.scss"
import "./src/assets/css/pages/photocompetition.scss"

import './src/assets/css/components/our-work/first-level/banner.scss'
import './src/assets/css/components/our-work/first-level/media.scss'
import './src/assets/css/components/our-work/second-level/banner.scss'
import './src/assets/css/components/our-work/second-level/contribution-train.scss'
import './src/assets/css/components/our-work/second-level/media.scss'

import './src/assets/css/modals/team-member-modal.scss'